// Polaris styles
import '@amzn/awsui-global-styles/polaris.css';

import { I18nProvider } from '@amzn/awsui-components-react/polaris/i18n';
import i18NMessages from '@amzn/awsui-components-react/polaris/i18n/messages/all.all';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import { createPortal } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { css } from '@linaria/core';
import { applyMode, Mode } from '@amzn/awsui-global-styles';
import { StrictMode } from 'react';
import App from './App';
import CommonHeader from './components/CommonHeader';
import { InstrumentationProvider } from './context/InstrumentationProvider';
import { NotificationsProvider } from './context/NotificationsProvider';
import { assertDefined } from './utils/assertions';
import { AppSettingsProvider } from './context/AppSettingsProvider';
import { MidwayProvider } from './context/MidwayProvider';
import { ControlPlaneClientManagerProvider } from './context/ControlPlaneClientManagerProvider';
import { AnalyticsAccountS3ClientProvider } from './context/AnalyticsAccountS3ClientProvider';
import { LegacyAnalyticsAccountS3ClientProvider } from './context/LegacyAnalyticsAccountS3ClientProvider';
import { AnalyticsAccountDynamoDbClientProvider } from './context/AnalyticsAccountDynamoDbClientProvider';
import { ApolloClientProvider } from './context/ApolloClientProvider';
import { FeatureFlagsProvider } from './context/FeatureFlagsProvider';
import { LegacyIamRolesProvider } from './context/LegacyIamRolesProvider';
import { MgmtCellClientManagerProvider } from './context/MgmtCellClientManagerProvider';
import { PermissionsProvider } from './context/PermissionsProvider';

/** This produces a flash of red for any element update
 * in development mode to help us identify unnecessary
 * or unexpected re-renders.
 */
if (import.meta.env.DEV) {
  const styleTag = document.createElement('style');
  styleTag.innerHTML = `
    @keyframes rerender-animation {
      0% {
        background-color: #ff0000;
      }
      100% {
        background-color: initial;
      }
    }

    * {
      animation: rerender-animation 0.5s;
    }
  `;
  document.head.appendChild(styleTag);
}

// Format the header to be fixed at the top of the page.
css`
  :global() {
    #h {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1002;
      margin: 0;
    }
  }
`;

// Apply UI display mode here, where it is set before we start rendering
const darkModeFromLocalStorage = window.localStorage.getItem('darkMode');
const darkModeFromSystemPreferences = window.matchMedia('(prefers-color-scheme: dark)').matches;
document.body.classList.add('awsui-visual-refresh');

if (darkModeFromLocalStorage !== null) {
  applyMode(darkModeFromLocalStorage === 'true' ? Mode.Dark : Mode.Light);
} else {
  applyMode(darkModeFromSystemPreferences ? Mode.Dark : Mode.Light);
}

// Render main application to #app
createRoot(assertDefined(document.querySelector('#app'))).render(
  <StrictMode>
    <I18nProvider locale={navigator.languages[0]} messages={[i18NMessages]}>
      <BrowserRouter>
        <FeatureFlagsProvider>
          <NotificationsProvider>
            <QueryClientProvider client={new QueryClient()}>
              <AppSettingsProvider>
                <MidwayProvider>
                  <InstrumentationProvider>
                    <ApolloClientProvider>
                      <PermissionsProvider>
                        <LegacyIamRolesProvider>
                          <AnalyticsAccountDynamoDbClientProvider>
                            <AnalyticsAccountS3ClientProvider>
                              <LegacyAnalyticsAccountS3ClientProvider>
                                <MgmtCellClientManagerProvider>
                                  <ControlPlaneClientManagerProvider>
                                    {/* Render header to #h via a portal */}
                                    {createPortal(<CommonHeader />, assertDefined(document.querySelector('#h')))}
                                    <App />
                                  </ControlPlaneClientManagerProvider>
                                </MgmtCellClientManagerProvider>
                              </LegacyAnalyticsAccountS3ClientProvider>
                            </AnalyticsAccountS3ClientProvider>
                          </AnalyticsAccountDynamoDbClientProvider>
                        </LegacyIamRolesProvider>
                      </PermissionsProvider>
                    </ApolloClientProvider>
                  </InstrumentationProvider>
                </MidwayProvider>
              </AppSettingsProvider>
            </QueryClientProvider>
          </NotificationsProvider>
        </FeatureFlagsProvider>
      </BrowserRouter>
    </I18nProvider>
  </StrictMode>,
);
