import * as React from 'react';
import { ApolloProvider, InMemoryCache, ApolloClient, HttpLink, split, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition, removeDirectivesFromDocument } from '@apollo/client/utilities';
import type { DocumentNode, StringValueNode } from 'graphql';
import { Kind, OperationTypeNode, GraphQLScalarType } from 'graphql';
import { withScalars } from 'apollo-link-scalars';
import { makeExecutableSchema } from '@graphql-tools/schema';
import { RetryLink } from '@apollo/client/link/retry';
import { typeDefs } from '../code-generated/graphqlSchemaTypeDefs.generated';
import { useAppSettings } from './AppSettings';
import { useInstrumentation } from './Instrumentation';
import { useMidwayToken } from './Midway';
import { useAddNotification } from './Notifications';
import { TimeoutLink } from '../utils/TimeoutLink';
import { DataProductApi } from '../constants/api';
import { useDataProductEndpoints } from '../hooks/useDataProductEndpoints';

// The GraphQLError type is not actually what AppSync uses, so we have to patch it here
// See https://github.com/aws/aws-appsync-community/issues/71
declare module 'graphql' {
  interface GraphQLError {
    errorType: string;
  }
}

const findDirectiveValue = (query: DocumentNode, directiveName: string, argName: string) => {
  return (
    query.definitions
      .find(definition => definition.kind === Kind.OPERATION_DEFINITION)
      ?.directives?.find(directive => directive.name.value === directiveName)
      ?.arguments?.find(argument => argument.name.value === argName)?.value as StringValueNode | undefined
  )?.value;
};

export function ApolloClientProvider(props: { readonly children: React.ReactNode }) {
  const { graphqlApiUrl } = useAppSettings();
  const apiEndpointConfig = useDataProductEndpoints();
  const midwayToken = useMidwayToken();
  const addNotification = useAddNotification();
  const { rumClient } = useInstrumentation();

  // Suppress unauthorized graphql errors
  const unauthorizedHandlerLink = React.useMemo(
    () =>
      new ApolloLink((operation, forward) => {
        const result = forward(operation);
        return result.map(({ data, errors }) => {
          const filteredErrors =
            errors?.filter(error => (error as unknown as { errorType?: string }).errorType !== 'Unauthorized') ?? [];
          return {
            data,
            ...(filteredErrors.length > 0 ? { errors: filteredErrors } : {}),
          };
        });
      }),
    [],
  );

  const errorLink = React.useMemo(
    () =>
      onError(({ graphQLErrors, networkError, operation }) => {
        if (networkError) {
          if ('statusCode' in networkError && networkError.statusCode === 401) {
            // Skip 401 errors
            return;
          }
          rumClient?.recordError(networkError);
          console.error(`[Network error]: ${networkError.message}`, networkError);
          addNotification({
            header: 'Network error',
            message: networkError.message,
            type: 'error',
          });
        } else {
          for (const graphqlError of graphQLErrors ?? []) {
            rumClient?.recordError(graphqlError);
            console.error(
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              `[GraphQL error]: Operation: ${operation.operationName}, Message: ${graphqlError.message}, Location: ${graphqlError.locations}, Path: ${graphqlError.path}`,
            );
            addNotification({
              header: 'GraphQL error',
              message: graphqlError.message,
              type: 'error',
            });
          }
        }
      }),
    [rumClient, addNotification],
  );

  const retryLink = React.useMemo(
    () =>
      new RetryLink({
        delay: {
          initial: 300,
          max: Infinity,
          jitter: true,
        },
        attempts: {
          max: 5,
          retryIf: (networkError: object, _operation) => {
            if (isNetworkErrorWithStatusCode(networkError)) {
              return [
                408, // Request Timeout
                435, // Too Early
                429, // Too Many Requests
                502, // Bad Gateway
                503, // Service Unavailable
                504, // Gateway Timeout
              ].includes(networkError.statusCode);
            }
            return true;
          },
        },
      }),
    [],
  );

  const timeoutLink = React.useMemo(
    () =>
      new TimeoutLink({
        timeoutMs: 8000,
      }),
    [],
  );

  const scalarsLink = React.useMemo(
    () =>
      withScalars({
        schema: makeExecutableSchema({
          typeDefs,
          resolvers: {
            AWSDateTime,
            AWSJSON,
          },
        }),
      }),
    [],
  );

  // TODO - Support GraphQL subscriptions.
  const apiRoutingLink = React.useMemo(
    () =>
      new ApolloLink((operation, forward) => {
        // Default to trying curated API when we don't have specific one.
        const apiName = findDirectiveValue(operation.query, 'api', 'name');
        operation.query =
          removeDirectivesFromDocument([{ name: 'api', remove: true }], operation.query) ?? operation.query;
        const api =
          apiName !== undefined && Object.values(DataProductApi).includes(apiName as DataProductApi)
            ? (apiName as DataProductApi)
            : DataProductApi.CURATED_API;

        operation.setContext({
          ...operation.getContext(),
          apiName: api,
        });
        return forward(operation);
      }),
    [],
  );

  const httpLink = React.useMemo(
    () =>
      new HttpLink({
        uri: ({ getContext }) => {
          const { apiName } = getContext() as { apiName: DataProductApi };
          return apiEndpointConfig[apiName];
        },
        headers: {
          Authorization: `Bearer ${midwayToken}`,
        },
      }),
    [midwayToken, apiEndpointConfig],
  );

  const wsLink = React.useMemo(
    () =>
      new GraphQLWsLink(
        createClient({
          url: graphqlApiUrl,
          connectionParams: {
            headers: {
              Authorization: `Bearer ${midwayToken}`,
            },
          },
        }),
      ),
    [midwayToken, graphqlApiUrl],
  );

  const link = React.useMemo(() => {
    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === Kind.OPERATION_DEFINITION && definition.operation === OperationTypeNode.SUBSCRIPTION;
      },
      wsLink,
      ApolloLink.from([retryLink, timeoutLink, httpLink]),
    );
    return ApolloLink.from([scalarsLink, errorLink, unauthorizedHandlerLink, apiRoutingLink, splitLink]);
  }, [scalarsLink, errorLink, unauthorizedHandlerLink, retryLink, timeoutLink, apiRoutingLink, httpLink, wsLink]);

  const client = React.useMemo(
    () =>
      new ApolloClient({
        cache: new InMemoryCache({
          typePolicies: {
            GsocContactDetails: {
              keyFields: ['contactId'],
            },
            GsocDataflow: {
              keyFields: ['dataflowId', 'destination', ['config', ['id']], 'source', ['config', ['id']]],
            },
            GsocCapability: {
              keyFields: ['id'],
            },
          },
        }),
        connectToDevTools: true,
        link,
        // THIS IS NOT WORKING
        // See https://github.com/apollographql/apollo-client/issues/9107
        defaultOptions: {
          query: {
            errorPolicy: 'all',
            returnPartialData: true,
          },
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Whenever the link changes, change it for our client
  React.useEffect(() => {
    client.setLink(link);
    // Setup the garbage collection to run every 5 minutes
    const intervalHandle = setInterval(() => client.cache.gc(), 5 * 60 * 1000);
    return () => clearInterval(intervalHandle);
  }, [client, link]);
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}

const AWSDateTime = new GraphQLScalarType({
  name: 'AWSDateTime',
  description: 'An ISO-8601 encoded UTC datetime string.',
  serialize: value => (value as Date).toISOString(),
  parseValue: value => new Date(value as string | number),
  parseLiteral: ast => (ast.kind === Kind.STRING || ast.kind === Kind.INT ? new Date(ast.value) : null),
});

const AWSJSON = new GraphQLScalarType({
  name: 'AWSJSON',
  description: 'An untyped json blob',
  serialize: value => JSON.stringify(value),
  parseValue: value => {
    if (typeof value === 'string') {
      return JSON.parse(value) as unknown;
    }
    return null;
  },
  parseLiteral: ast => (ast.kind === Kind.STRING ? (JSON.parse(ast.value) as unknown) : null),
});

function isNetworkErrorWithStatusCode(err: object): err is { statusCode: number } {
  return 'statusCode' in err;
}
